<template>
	<section class="min-w1600">
		<div class="main-contents-wrapper">
			<article class="total-info-container">
				<div class="total-info-wrap">
					<div class="total-info-item">
						<img :src="require('@/assets/img/searchicon.png')"/>
						<i class="fas fa-search"></i>
						<span>{{ $t('searchArea.schResult') }}</span>
					</div>
					<div class="total-info-item">
						<span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span
							class="value">{{ pageInfo.tatal_list_count }}</span>
					</div>
					<div class="total-info-item">
						<span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{
                        pageInfo.page
						}}</span>
					</div>
					<div class="total-info-item">
						<span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span
							class="value">{{ pageInfo.tatal_page_count }}</span>
					</div>
				</div>
				<button type="button" class="btn-layout btn-grey" @click="modalOpen('c')">{{ $t('button.add') }}</button>
			</article>
			<div class="table-wrapper">
				<table class="mainTable">
					<table-head :headInfo="headInfo"/>
					<tbody>
					<template v-if="tableData.length !== 0">
						<tr v-for="(item, idx) in tableData" :key="item.blockIdx">
							<td class="roboto">{{ idx + 1 }}</td>
							<td>{{item.domain}}</td>
							<td>{{ item.memId }}</td>
							<td>{{ item.memNick}}</td>
							<td class="roboto">{{ item.regDate.replace('T', ' ') }}</td>
							<td style="width: 5%">
								<div class="box-ui-check searchbar-content">
									<input type="checkbox" class="set-switch" v-model="item.useYnBoolean" disabled="disabled"/>
								</div>
							</td>
							<td>
								<div class="status-change-btn-wrap">
									<button class="btn-innerTable btn-status-change wait" type="button" @click="modalOpen('u', item)">{{ $t('button.modify') }}</button>
									<button class="btn-innerTable btn-status-change wait" type="button" @click="removeDomain(item)">{{ $t('button.del') }}
									</button>
								</div>
							</td>
						</tr>
					</template>
					<template v-else>
						<td colspan="7">{{ $t('txt.noData') }}</td>
					</template>
					</tbody>
				</table>
			</div>
		</div>
		<pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page"
					:pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count"/>

		<div class="modal-wrapper" v-if="updModalActive">
			<div class="modal-wrap">
				<article>
					<div class="sub-title">
						<h3 v-if="modalType === 'u'">{{ $t('button.modify') }}</h3>
						<h3 v-if="modalType === 'c'">{{ $t('button.add') }}</h3>
					</div>
					<div class="page-content">
						<div class="item-row-container">
							<div class="item-wrap">
								<div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.domain') }}</div>
								<div class="item-content">
									<input type="text" v-model="saveData.domain"/>
								</div>
							</div>
						</div>
						<div class="item-row-container">
							<div class="item-wrap">
								<div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.partnerId') }}</div>
								<div class="item-content">
									<input type="text" v-model="saveData.memId"/>
								</div>
							</div>
						</div>
						<div class="item-row-container">
							<div class="item-wrap">
								<div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.use') }}</div>
								<div class="item-content">
									<input type="checkbox" class="set-switch"
										   v-model="option.useYn"/>
								</div>
							</div>
						</div>
						<div class="modal-btns">
							<a @click="updateDomain">{{ $t('button.save') }}</a>
							<a @click="modalClose()">{{ $t('button.close') }}</a>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
</template>

<script>
import TableHead from '@/components/main/table/Head.vue'
import Pagination from '@/components/common/Pagination'
import { getSiteData } from '@/libs/auth-helper'
import { deleteDomain, getDomainList, saveDomain } from '@/api/setting.js'
import { mapState } from 'vuex'

export default {
  name: 'CompanyDomain',
  components: {
    TableHead,
    Pagination
  },
  data () {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['index', 'domain', 'partnerId', 'partnerNick', 'enterDate', 'use', 'option']
      },
      reqData: {
        page: 1,
        count_per_list: 30
      },
      saveData: {
        domain: '',
        memId: '',
        useYn: 'Y'
      },
      option: {
        useYn: true
      },
      initSaveData: {
        domain: '',
        memId: '',
        useYn: 'Y'
      },
      tableData: [],
      pageInfo: {
        tatal_list_count: 0,
        page: 0,
        tatal_page_count: 0
      },
      totalInfo: {},
      siteInfo: {},
      updModalActive: false,
      modalType: 'c'
    }
  },
  methods: {
    async removeDomain (item) {
      const param = {
        domain: item.domain
      }

      if (confirm(`${item.domain} 도메인을 삭제하시겠습니까?`)) {
        await deleteDomain(param).then(async res => {
          if (res.resultCode === '0') {
            alert('삭제가 완료되었습니다.')
            this.saveData = { ...this.initSaveData }
            await this.setTableData(1)
          } else {
            alert(res.resultMessage)
          }
        })
      }
    },
    async updateDomain () {
      const param = {
        ...this.saveData,
        useYn: this.option.useYn ? 'Y' : 'N'
      }
      console.log(param, this.siteInfo)
      await saveDomain(param).then(async res => {
        console.log(res)
        if (res.resultCode === '0') {
          alert('처리가 완료되었습니다.')
          await this.setTableData(1)
          this.updModalActive = false
        } else {
          alert(res.resultMessage)
        }
      })
    },
    modalOpen (type, item) {
      this.updModalActive = true
      this.modalType = type
      if (type === 'u' && item) {
        this.saveData = {
          ...item
        }
      }
    },
    modalClose () {
      this.updModalActive = false
      this.saveData = { ...this.initSaveData }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async setTableData (page) {
      if (!page) {
        this.reqData.page = 1
      }
      const req = { ...this.reqData }
      const res = await getDomainList(req)
      console.log(res.data)
      if (res.resultCode === '0') {
        this.tableData = res.data.domainList
        this.tableData.forEach(item => {
          item.useYnBoolean = item.useYn === 'Y'
        })
      }

      if (res.data.pageInfo) {
        res.data.pageInfo.tatal_page_count = Math.ceil(Number(res.data.pageInfo.tatal_list_count) / 30)
        this.pageInfo = res.data.pageInfo
      }

      console.log(this.tableData)
      console.log(this.pageInfo)
    }
  },
  async created () {
    this.siteInfo = this.getSiteInfo()
    await this.setTableData(1)
  }
}
</script>

<style scoped>
.sub-title {
   color: #646464;
   font-size: 13px;
   padding-bottom: 11px;
   border-bottom: 2px solid #959595;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.modal-wrapper {
    display: flex;
}

.item-wrap > div.item-title {
    min-width: 110px;
}

.tableInnerImg {
    width: 120px;
}

.mainTable tr > td:nth-child(2) {
    width: 600px;
    white-space: normal;
}
</style>
